import { gql } from "@apollo/client";

export interface IDeleteMedia {
  fileName: string;
  id: string;
}

export interface IDeleteMediaData {
  deleteMedia: IDeleteMedia;
}

export interface IDeleteMediaVars {
  id: number;
}

export const DELETE_MEDIA = gql`
  mutation deleteMedia($id: ID!) {
    deleteMedia(id: $id) {
      id
      fileName
    }
  }
`;

//*-------Delete locale
export interface IDeleteMediaLocale {
  id: string;
  localeId: string;
}

export interface IDeleteMediaLocaleData {
  deleteMediaLocale: IDeleteMediaLocale;
}
export interface IDeleteMediaLocaleVars {
  id: number;
}

export const DELETE_MEDIA_LOCALE = gql`
  mutation deleteMediaLocale($id: ID!) {
    deleteMediaLocale(id: $id) {
      id
      localeId
    }
  }
`;
