import { gql } from "@apollo/client";
import { IStation } from "../station/station.query";
import { IMedia } from "../media/media.query";

export interface IClassicTour {
  id: string;
  duration: number | null;
  published: boolean;

  locale?: {
    id: string;
    title: string | null;
  };

  created: string;
}

export interface IClassicToursData {
  allClassicTours: IClassicTour[];
}

export const ALL_CLASSIC_TOURS = gql`
  query allClassicTours {
    allClassicTours {
      id
      duration
      published
      locale(lang: "sr") {
        id
        title
      }
      created
    }
  }
`;

export interface IClassicTourDetails {
  id: string;
  duration: number | null;
  published: boolean;
  allStations: IStation[];

  allLocales?: {
    id: string;
    title: string | null;
    description: string | null;
    gallery?: IMedia[] | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
  created: string;
}

export interface IClassicTourDetailsData {
  oneClassicTourDetails: IClassicTourDetails;
}

export interface IClassicTourDetailsVars {
  id: number;
}

export const ONE_CLASSIC_TOUR_DETAILS = gql`
  query oneClassicTourDetails($id: ID!) {
    oneClassicTourDetails(id: $id) {
      id
      duration
      published
      allLocales {
        id
        title
        description
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
          locale {
            id
            alt
            languageFlag {
              id
              isoLanguageCode
            }
          }
        }
        languageFlag {
          id
          isoLanguageCode
        }
      }
      allStations {
        id
        published
        created
        children {
          id
          published
          created
          locale {
            id
            title
          }
          children {
            id
            published
            created
            locale {
              id
              title
            }
          }
        }

        locale {
          id
          title
        }
      }
      created
    }
  }
`;
