import {
  Button,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useStyles } from "./AboutUsPage.styles";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  IDialogContext,
  IMediaSelectDialogContext,
  INPUT_SUBJECT_ABOUT_MAX_LENGTH,
  INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH,
  INPUT_SUBJECT_NAME_MAX_LENGTH,
  INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  useForm,
} from "../../../utils";
import { initialInputData } from "./AboutUsPage.inputs";
import {
  AddOutlined as AddOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Reference, StoreObject, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SUBJECT_LOCALE,
  DELETE_OPENING_HOUR,
  DELETE_SUBJECT_LOCALE,
  ICreateSubjectLocaleData,
  ICreateSubjectLocaleVars,
  IDeleteOpeningHourData,
  IDeleteOpeningHourVars,
  IDeleteSubjectLocaleData,
  IDeleteSubjectLocaleVars,
  IUpdateSubjectLocaleData,
  IUpdateSubjectLocaleVars,
  UPDATE_SUBJECT_LOCALE,
} from "../../../apollo/mutations";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { OpeningHourItem } from "./components";
import { DeleteDialog } from "../../../components/deleteDialog/DeleteDialog";
import { UpsertOpeningHourDialog } from "./components/upsertOpeningHourDialog/UpsertOpeningHourDialog";
import {
  ALL_OPENING_HOURS,
  ALL_SUBJECT_LOCALES,
  IOpeningHoursData,
  ISubjectLocalesData,
} from "../../../apollo/queries";

export const AboutUsPage: FC = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { classes: globalClasses } = useGlobalStyles();

  const TOTAL_TABS = "2";

  const [activeTab, setActiveTab] = useState("1");
  const [openDialog, setOpenDialog] = useState<IDialogContext>({
    open: false,
    id: undefined,
    type: undefined,
  });

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({
      ...prevState,
      open: false,
      id: undefined,
    }));
  };

  const handleOpenCreateDialog = () => {
    setOpenDialog({
      type: "create",
      id: undefined,
      open: true,
    });
  };

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const { loading, data, error, refetch } =
    useQuery<ISubjectLocalesData>(ALL_SUBJECT_LOCALES);

  const {
    loading: loadingOpeningHours,
    data: dataOpeningHours,
    error: errorOpeningHours,
  } = useQuery<IOpeningHoursData>(ALL_OPENING_HOURS);

  const {
    inputFields,
    resetFields,
    validateForm,
    getFormValuesFromFetchedData,
    activeLocale,
    setActiveLocale,
    setCurrentLanguageFlags,
    didValuesChange,
    currentLanguageFlags,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [
    deleteSubjectLocaleMutation,
    { loading: loadingDeleteSubjectLocaleMutation },
  ] = useMutation<IDeleteSubjectLocaleData, IDeleteSubjectLocaleVars>(
    DELETE_SUBJECT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Jezik lokalizacije je obrisan", {
          variant: "success",
        });
        resetFields(["name", "about", "extraInfo", "openingHoursInfo"]);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteSubjectLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Fix cache update
      update(cache, { data: dataCreate }) {
        if (data?.allSubjectLocales) {
          cache.modify({
            id: cache.identify(
              data.allSubjectLocales as unknown as StoreObject
            ),
            fields: {
              allSubjectLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteSubjectLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const [
    updateSubjectLocaleMutation,
    { loading: loadingUpdateSubjectLocaleMutation },
  ] = useMutation<IUpdateSubjectLocaleData, IUpdateSubjectLocaleVars>(
    UPDATE_SUBJECT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Ažurirano o nama", {
          variant: "success",
        });
        resetFields(
          ["name", "about", "extraInfo", "openingHoursInfo", "gallery"],
          true,
          false
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    deleteOpeningHourMutation,
    { loading: loadingDeleteOpeningHourMutation },
  ] = useMutation<IDeleteOpeningHourData, IDeleteOpeningHourVars>(
    DELETE_OPENING_HOUR,
    {
      onCompleted: (res) => {
        enqueueSnackbar("Radno vreme je obrisano!", { variant: "success" });
        handleCloseDialog();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            allOpeningHours(existingData: Array<Reference>, { readField }) {
              if (data) {
                return existingData.filter(
                  (taskRef) =>
                    data.deleteOpeningHour.id !== readField("id", taskRef)
                );
              }
            },
          },
        });
      },
    }
  );

  const handleDeleteOpeningHour = () => {
    if (openDialog.id && openDialog.type === "delete") {
      deleteOpeningHourMutation({ variables: { id: +openDialog.id } });
    } else {
      enqueueSnackbar("Poziv za brisanje nije uspeo");
    }
  };

  const [
    createSubjectLocaleMutation,
    { loading: loadingCreateSubjectLocaleMutation },
  ] = useMutation<ICreateSubjectLocaleData, ICreateSubjectLocaleVars>(
    CREATE_SUBJECT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Lokalizacija je kreirana", {
          variant: "success",
        });
        resetFields(
          ["name", "about", "extraInfo", "openingHoursInfo", "gallery"],
          true
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingListData: ISubjectLocalesData | null = cache.readQuery({
          query: ALL_SUBJECT_LOCALES,
        });
        if (data?.createSubjectLocale) {
          const newLocaleData: any = {
            ...data.createSubjectLocale,
          };
          cache.writeQuery({
            query: ALL_SUBJECT_LOCALES,
            data: {
              allSubjectLocales: existingListData?.allSubjectLocales
                ? [newLocaleData, ...existingListData.allSubjectLocales]
                : [newLocaleData],
            },
          });
        }
      },
    }
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
  };

  useEffect(() => {
    // console.log("useEffect");
    if (!data?.allSubjectLocales?.length) {
      return;
    }

    const formatedData = {
      allLocales: data.allSubjectLocales,
    };

    getFormValuesFromFetchedData(
      formatedData,
      [
        {
          fromDataProperty: "allLocales.name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "allLocales.about",
          toFormProperty: "about",
        },
        {
          fromDataProperty: "allLocales.extraInfo",
          toFormProperty: "extraInfo",
        },
        {
          fromDataProperty: "allLocales.openingHoursInfo",
          toFormProperty: "openingHoursInfo",
        },
        {
          fromDataProperty: "allLocales.gallery.id",
          toFormProperty: "gallery",
        },
      ],
      true,
      true
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleUpdateLocale = () => {
    const getUpdateLocaleId = data?.allSubjectLocales?.find(
      (x) => x.languageFlag?.isoLanguageCode === activeLocale
    )?.id;

    if (!validateForm(["name", "about", "extraInfo", "name"])) {
      return;
    }

    if (getUpdateLocaleId) {
      updateSubjectLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            name: handleDataToVar("name", "string"),
            about: handleDataToVar("about", "string"),
            extraInfo: handleDataToVar("extraInfo", "string"),
            openingHoursInfo: handleDataToVar("openingHoursInfo", "string"),
            gallery: handleDataToVar("gallery", "numberArray"),
          },
        },
      });
    } else {
      createSubjectLocaleMutation({
        variables: {
          data: {
            name: handleDataToVar("name", "string", false),
            about: handleDataToVar("about", "string", false),
            extraInfo: handleDataToVar("extraInfo", "string", false),
            openingHoursInfo: handleDataToVar(
              "openingHoursInfo",
              "string",
              false
            ),
            isoLanguageCode: activeLocale,
            gallery: handleDataToVar("gallery", "numberArray", false),
          },
        },
      });
    }
  };

  const handleDeleteSubjectLocale = () => {
    const localeId = data?.allSubjectLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteSubjectLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields(["name", "about", "openingHoursInfo", "extraInfo"]);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Lokalizacija je uklonjena!", { variant: "success" });
    }
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Opšte" value="1" />
          <Tab label="Radno vreme" value="2" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div className={globalClasses.paperTitle}>
            <div className={globalClasses.justifySpaceBetween}>
              <Typography component="h1" variant="h5">
                O nama
              </Typography>
            </div>
          </div>

          <DataHandlerComponent
            hasData={Boolean(
              data?.allSubjectLocales && dataOpeningHours?.allOpeningHours
            )}
            error={Boolean(error || errorOpeningHours)}
            loading={loading || loadingOpeningHours}
          >
            <div className={globalClasses.paperContainer}>
              <TabPanel value="1" className={classes.tabPanel}>
                <FlagList
                  deleteLocaleFlagMutation={handleDeleteSubjectLocale}
                  canSelectFlags
                  activeLocale={activeLocale}
                  setActiveLocale={setActiveLocale}
                  currentLanguageFlags={currentLanguageFlags}
                  setCurrentLanguageFlags={setCurrentLanguageFlags}
                  type="Organizacija"
                  tooltip="Novi prevodi biće vidljivi korisnicima samo ako ih prvo postavite ovdje"
                />
                <Typography color="textSecondary">
                  Dodajte informacije o vašoj organizaciji.
                </Typography>
                <TextField
                  margin="normal"
                  {...inputFields.name.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_NAME_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.name.inputProps.value.length}/${INPUT_SUBJECT_NAME_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={4}
                  pickType="image"
                  {...inputFields.gallery.inputProps}
                />
                <TextField
                  margin="normal"
                  {...inputFields.about.inputProps}
                  autoFocus
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_ABOUT_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.about.inputProps.value.length}/${INPUT_SUBJECT_ABOUT_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.extraInfo.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.extraInfo.inputProps.value.length}/${INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  {...inputFields.openingHoursInfo.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.openingHoursInfo.inputProps.value.length}/${INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value="2">
                <div className={globalClasses.justifySpaceBetween}>
                  <Typography component="p" variant="h6">
                    Radno vreme
                  </Typography>
                  <Button
                    onClick={handleOpenCreateDialog}
                    variant="outlined"
                    startIcon={<AddOutlinedIcon />}
                  >
                    Dodaj novo
                  </Button>
                </div>
                <TableContainer className={globalClasses.tableContainer}>
                  <Table
                    className={globalClasses.table}
                    // sx={{ minWidth: 650 }}
                    aria-label="List of opening hours"
                  >
                    <TableHead>
                      <TableRow
                      // className={classes.tableRow}
                      >
                        <TableCell width={32} align="left">
                          #
                        </TableCell>
                        <TableCell align="left">Dan</TableCell>
                        <TableCell width={160} align="left">
                          Vreme otvaranja
                        </TableCell>
                        <TableCell width={160} align="left">
                          Vreme zatvaranja
                        </TableCell>
                        <TableCell width={180} align="right">
                          Akcije
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataOpeningHours?.allOpeningHours?.length
                        ? dataOpeningHours.allOpeningHours.map((item, i) => {
                            const handleOpenDeleteDialog = () => {
                              setOpenDialog({
                                open: true,
                                id: item.id,
                                type: "delete",
                              });
                            };
                            const handleOpenEditDialog = () => {
                              setOpenDialog({
                                open: true,
                                id: item.id,
                                type: "update",
                              });
                            };
                            return (
                              <OpeningHourItem
                                key={item.id}
                                index={i + 1}
                                data={item}
                                handleDelete={handleOpenDeleteDialog}
                                handleUpdate={handleOpenEditDialog}
                              />
                            );
                          })
                        : null}
                    </TableBody>
                  </Table>
                  <Typography color="textSecondary">
                    Radno vreme vaše organizacije. Za dvostruko radno vreme,
                    dodajte dva radna vremena za isti dan.
                  </Typography>
                </TableContainer>
              </TabPanel>
            </div>
            <div className={globalClasses.paperButtons}>
              <div className={globalClasses.paperButtonsLeft}>
                {activeTab !== "1" ? (
                  <Button
                    className={classes.buttonPrevious}
                    onClick={handlePrevTab}
                    variant="outlined"
                    color="inherit"
                    startIcon={<ChevronLeftOutlinedIcon />}
                  >
                    Prethodno
                  </Button>
                ) : null}
              </div>
              <div className={globalClasses.paperButtonsRight}>
                {activeTab !== TOTAL_TABS ? (
                  <Button
                    onClick={handleNextTab}
                    variant="outlined"
                    endIcon={<ChevronRightOutlinedIcon />}
                  >
                    Sledeće
                  </Button>
                ) : null}
                {activeTab === "1" ? (
                  <Button
                    onClick={handleUpdateLocale}
                    variant="contained"
                    disabled={
                      !didValuesChange([
                        "name",
                        "about",
                        "extraInfo",
                        "openingHoursInfo",
                        "gallery",
                      ]) &&
                      !!data?.allSubjectLocales?.find(
                        (x) => x.languageFlag?.isoLanguageCode === activeLocale
                      )?.id
                    }
                  >
                    {!data?.allSubjectLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                      ? "kreiraj "
                      : "ažuriraj "}
                    | {activeLocale} |
                  </Button>
                ) : null}
              </div>
            </div>
          </DataHandlerComponent>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingUpdateSubjectLocaleMutation ||
          loadingCreateSubjectLocaleMutation ||
          loadingDeleteOpeningHourMutation ||
          loadingDeleteSubjectLocaleMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
      <DeleteDialog
        open={openDialog.type === "delete" && openDialog.open}
        title="Izbriši ovo radno vreme?"
        description="Ova akcija je trajna!"
        onClose={handleCloseDialog}
        mutation={handleDeleteOpeningHour}
      />
      <UpsertOpeningHourDialog
        open={
          (openDialog.type === "create" || openDialog.type === "update") &&
          openDialog.open
        }
        type={openDialog.type}
        id={openDialog.id}
        onClose={handleCloseDialog}
        refetch={refetch}
      />
    </PageLayout>
  );
};
