import { gql } from "@apollo/client";

export interface IMedia {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;

  locale: {
    id: string;
    alt: string | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  } | null;

  allLocales:
    | {
        id: string;
        alt: string | null;
        languageFlag: {
          id: string;
          isoLanguageCode: string;
        };
      }[]
    | null;

  // _count: {
  //   StationMedia: number;
  //   TourMedia: number;
  // };
}

export interface IMediaWithoutCount {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;

  locale: {
    id: string;
    alt: string | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  } | null;

  allLocales:
    | {
        id: string;
        alt: string | null;
        languageFlag: {
          id: string;
          isoLanguageCode: string;
        };
      }[]
    | null;
}

export interface IMediasData {
  allMedias?: IMedia[];
}

export interface IMediasVars {
  type: "audio" | "image" | undefined;
}

export const ALL_MEDIAS = gql`
  query allMedias($type: String) {
    allMedias(type: $type) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension

      locale {
        id
        alt
        languageFlag {
          id
          isoLanguageCode
        }
      }
      # _count {
      #   StationMedia
      #   TourMedia
      # }
    }
  }
`;
export interface IMediaData {
  oneMedia: IMedia;
}

export interface IMediaVars {
  id: number;
}

export const ONE_MEDIA = gql`
  query oneMedia($id: ID!) {
    oneMedia(id: $id) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension

      allLocales {
        id
        alt
        languageFlag {
          id
          isoLanguageCode
        }
      }
      # alt
      # _count {
      #   StationMedia
      #   TourMedia
      # }
    }
  }
`;
