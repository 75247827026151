import { createTypedDynamicFormInputs } from "../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  alt: {
    inputProps: {
      label: "Opis",
      name: "alt",
      placeholder:
        "Ogromna narandžasta robotska ruka pruža se prema napred, sa detaljima mehaničkih zglobova i futurističkog dizajna",
      required: true,
    },
    mainHelperText:
      'Tekst koji opisuje sliku. Ako je slika dekorativna (npr: plava pozadina sa šarom), staviti "-x-" bez navodnika. Opis je jako važan za pristupačnost.',
    localise: true,
  },
});
