import { FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Dialog,
} from "@mui/material";
// import { useStyles } from "./UpsertAdminDialog.styles";
import { useSnackbar } from "notistack";
import {
  Reference,
  StoreObject,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { initialInputData } from "./UpdateMediaDialog.inputs";
import {
  IMedia,
  IMediaData,
  IMediaVars,
  ONE_MEDIA,
} from "../../apollo/queries";
import { MAIN_ISO_LANGUAGE_CODE, useForm } from "../../utils";
import {
  CREATE_MEDIA_LOCALE,
  DELETE_MEDIA_LOCALE,
  ICreateMediaLocaleData,
  ICreateMediaLocaleVars,
  IDeleteMediaLocaleData,
  IDeleteMediaLocaleVars,
  IUpdateMediaLocaleData,
  IUpdateMediaLocaleVars,
  UPDATE_MEDIA_LOCALE,
} from "../../apollo/mutations";
import { DataHandlerComponent } from "../dataHandlerComponent/DataHandlerComponent";
import { LoadingBackdrop } from "../loadingBackdrop/LoadingBackdrop";
import { FlagList } from "../flagList/FlagList";

interface IProps {
  onClose: () => void;
  open: boolean;
  item: IMedia | undefined;
  refetch: () => {};
}

export const UpdateMediaDialog: FC<IProps> = (props) => {
  const { onClose, open, item, refetch } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    inputFields,
    resetFields,
    validateForm,
    didValuesChange,
    getFormValuesFromFetchedData,
    handleDataToVar,
    activeLocale,
    currentLanguageFlags,
    setCurrentLanguageFlags,
    setActiveLocale,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [queryOneMedia, { data, error, loading }] = useLazyQuery<
    IMediaData,
    IMediaVars
  >(ONE_MEDIA);

  useEffect(() => {
    if (open) {
      if (item?.id) {
        queryOneMedia({ variables: { id: +item.id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, item?.id]);

  const clearFields = () => {
    resetFields();
  };

  useEffect(() => {
    if (data?.oneMedia && data?.oneMedia.id === item?.id && open) {
      // console.log("DATA", data);
      getFormValuesFromFetchedData(data.oneMedia, [
        {
          fromDataProperty: "allLocales.alt",
          toFormProperty: "alt",
        },
      ]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  // console.log(inputFields.alt);

  const [
    deleteMediaLocaleMutation,
    { loading: loadingDeleteMediaLocaleMutation },
  ] = useMutation<IDeleteMediaLocaleData, IDeleteMediaLocaleVars>(
    DELETE_MEDIA_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Jezik medija je obrisan", {
          variant: "success",
        });
        resetFields(["alt"]);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteMediaLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataDelete }) {
        if (data?.oneMedia) {
          cache.modify({
            id: cache.identify(data.oneMedia as unknown as StoreObject),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataDelete) {
                    return existingData.filter(
                      (taskRef) =>
                        dataDelete.deleteMediaLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const [
    updateMediaLocaleMutation,
    { loading: loadingUpdateMediaLocaleMutation },
  ] = useMutation<IUpdateMediaLocaleData, IUpdateMediaLocaleVars>(
    UPDATE_MEDIA_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Lokalizacija je ažurirana", {
          variant: "success",
        });
        resetFields(["alt"], true);
      },

      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      onQueryUpdated: () => undefined,
    }
  );

  const [
    createMediaLocaleMutation,
    { loading: loadingCreateMediaLocaleMutation },
  ] = useMutation<ICreateMediaLocaleData, ICreateMediaLocaleVars>(
    CREATE_MEDIA_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Lokalizacija je kreirana", {
          variant: "success",
        });
        resetFields(["alt"], true);
        refetch?.();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data: dataCreate }) => {
        // console.log(cache);
        if (data?.oneMedia) {
          cache.modify({
            id: cache.identify(data.oneMedia as unknown as StoreObject),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createMediaLocale.__typename}:${dataCreate.createMediaLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleUpdateLocale = () => {
    if (!validateForm(["alt"], true)) {
      return null;
    }

    const getUpdateLocaleId = data?.oneMedia.allLocales?.find(
      (x) => x.languageFlag?.isoLanguageCode === activeLocale
    )?.id;

    if (getUpdateLocaleId) {
      updateMediaLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            alt: handleDataToVar("alt", "string"),
          },
        },
      });
    } else if (item?.id) {
      createMediaLocaleMutation({
        variables: {
          mediaId: +item.id,
          data: {
            alt: handleDataToVar("alt", "string", false),

            isoLanguageCode: activeLocale,
          },
        },
      });
    }
  };

  const handleDeleteMediaLocale = () => {
    const localeId = data?.oneMedia.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteMediaLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields();
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Lokalizacija je uklonjena!", { variant: "success" });
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: clearFields }}
    >
      <DialogTitle>Ažuriraj medij</DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          skeletonHeight={60}
          skeletonNum={2}
          hasData={Boolean(data?.oneMedia)}
        >
          <FlagList
            deleteLocaleFlagMutation={handleDeleteMediaLocale}
            canSelectFlags
            activeLocale={activeLocale}
            setActiveLocale={setActiveLocale}
            currentLanguageFlags={currentLanguageFlags}
            setCurrentLanguageFlags={setCurrentLanguageFlags}
            type="Medij"
            tooltip='Novi prevodi biće vidljivi korisnicima samo ako ste ih već dodali u odeljku "O nama".'
          />
          <TextField
            {...inputFields.alt.inputProps}
            margin="normal"
            fullWidth
          />
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Otkaži
        </Button>
        <Button
          onClick={handleUpdateLocale}
          variant="contained"
          disabled={
            !didValuesChange(["alt"])
            // &&
            // !!data?.oneMedia.allLocales?.find(
            //   (x) => x.languageFlag?.isoLanguageCode === activeLocale
            // )?.id //TODO: CHECK
          }
        >
          {!data?.oneMedia.allLocales?.find(
            (x) => x.languageFlag?.isoLanguageCode === activeLocale
          )?.id
            ? "Kreiraj "
            : "Ažuriraj "}
          | {activeLocale} |
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={
          loadingUpdateMediaLocaleMutation ||
          loadingCreateMediaLocaleMutation ||
          loadingDeleteMediaLocaleMutation
        }
      />
    </Dialog>
  );
};
