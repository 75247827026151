import { gql } from "@apollo/client";
import { EPuzzleType } from "../../../utils";
import { IMedia } from "../media/media.query";

export interface ICheckpoint {
  id: string;
  published: boolean;
  puzzleType: EPuzzleType | null;

  locale: {
    id: string;
    title: string | null;
    directions: string | null;
    directionsHint: string | null;
    opening: string | null;
    challenge: string | null;
    challengeHint: string | null;
    closing: string | null;
    facts: string | null;
  };

  created: string;
}

// export interface ICheckpointsData {
//   allCheckpoints: ICheckpoint[];
// }

// export const ALL_CHECKPOINTS = gql`
//   query allCheckpoints {
//     allCheckpoints {
//       id
//       published
//       locale {
//         id
//         title
//         directions
//         directionsHint
//         opening
//         challenge
//         challengeHint
//         solution
//         closing
//         facts
//       }
//       created
//     }
//   }
// `;

export interface ICheckpointDetails {
  id: string;
  published: boolean;
  puzzleType: EPuzzleType | null;

  allLocales?: {
    id: string;
    title: string;
    directions: string;
    directionsHint: string;
    opening: string;
    challenge: string;
    challengeHint: string;
    solution: string;
    closing: string;
    facts: string;
    challengeHintGallery: IMedia[] | null;
    directionsHintGallery: IMedia[] | null;
    factsGallery: IMedia[] | null;
    solutionHelperMedia: IMedia | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
  created: string;
}

export interface ICheckpointDetailsData {
  oneCheckpointDetails: ICheckpointDetails;
}

export interface ICheckpointDetailsVars {
  id: number;
}

export const ONE_CHECKPOINT_DETAILS = gql`
  query oneCheckpointDetails($id: ID!) {
    oneCheckpointDetails(id: $id) {
      id
      published
      puzzleType
      allLocales {
        id
        title
        directions
        directionsHint
        opening
        challenge
        challengeHint
        solution
        closing
        facts
        directionsHintGallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
          locale {
            id
            alt
            languageFlag {
              id
              isoLanguageCode
            }
          }
        }
        challengeHintGallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
          locale {
            id
            alt
            languageFlag {
              id
              isoLanguageCode
            }
          }
        }
        factsGallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
          locale {
            id
            alt
            languageFlag {
              id
              isoLanguageCode
            }
          }
        }
        solutionHelperMedia(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
          locale {
            id
            alt
            languageFlag {
              id
              isoLanguageCode
            }
          }
        }
        languageFlag {
          id
          isoLanguageCode
        }
      }

      created
    }
  }
`;
