import { gql } from "@apollo/client";

export interface IUpdateMediaLocale {
  id: string;
  alt: string | null;
}

export interface IUpdateMediaLocaleData {
  updateMediaLocale: IUpdateMediaLocale;
}
export interface IUpdateMediaLocaleVars {
  id: number;
  data: {
    alt: string | null | undefined;
  };
}

export const UPDATE_MEDIA_LOCALE = gql`
  mutation updateMediaLocale($id: ID!, $data: MediaLocaleUpdateInput!) {
    updateMediaLocale(id: $id, data: $data) {
      id
      alt
    }
  }
`;
