import { gql } from "@apollo/client";

export interface ICreateMedia {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;
}

export interface ICreateMediaData {
  createMedia: ICreateMedia;
}
export interface ICreateMediaVars {
  file: File;
}

export const CREATE_MEDIA = gql`
  mutation createMedia($file: Upload!) {
    createMedia(file: $file) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
    }
  }
`;

export interface ICreateMediaLocale {
  id: string;
  alt: string | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateMediaLocaleData {
  createMediaLocale: ICreateMediaLocale;
}
export interface ICreateMediaLocaleVars {
  mediaId: number;
  data: {
    alt: string | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_MEDIA_LOCALE = gql`
  mutation createMediaLocale($mediaId: ID!, $data: MediaLocaleCreateInput!) {
    createMediaLocale(mediaId: $mediaId, data: $data) {
      id
      alt
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;
