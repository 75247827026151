import { FC, useEffect, useState } from "react";
import { useStyles } from "./StatisticsPage.styles";
import { useGlobalStyles } from "../../../utils/theme";
import { useSnackbar } from "notistack";

import { joinClassNames, useForm } from "../../../utils";
import { initialInputData } from "./StatisticsPage.inputs";
import {
  CompletenessItem,
  DataHandlerComponent,
  InfoShowcase,
  PageLayout,
  ReviewItem,
} from "../../../components";
import {
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { endOfToday, format, formatISO, isDate } from "date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ALL_CLASSIC_TOURS,
  ALL_INTERACTIVE_TOURS,
  ALL_REVIEWS,
  GET_STATISTICS_CLASSIC_TOUR,
  GET_STATISTICS_INTERACTIVE_TOUR,
  GET_STATISTICS_INTERACTIVE_TOUR_COMPLETENESS,
  GET_STATISTICS_USERS,
  IClassicToursData,
  IInteractiveToursData,
  IReviewsData,
  IReviewsVars,
  IStatisticsClassicTourData,
  IStatisticsClassicTourVars,
  IStatisticsInteractiveTourCompletenessData,
  IStatisticsInteractiveTourCompletenessVars,
  IStatisticsInteractiveTourData,
  IStatisticsInteractiveTourVars,
  IStatisticsUsersData,
  IStatisticsUsersVars,
} from "../../../apollo/queries";
import enGb from "date-fns/locale/sr";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const StatisticsPage: FC = () => {
  const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("1");

  const [startDateUsersValue, setStartDateUsersValue] = useState<Date | null>(
    new Date("2024-1-22 00:00:00")
  );
  const [endDateUsersValue, setEndDateUsersValue] = useState<Date | null>(
    endOfToday()
  );

  const [startDateInteractiveValue, setStartDateInteractiveValue] =
    useState<Date | null>(new Date("2024-1-22 00:00:00"));
  const [endDateInteractiveValue, setEndDateInteractiveValue] =
    useState<Date | null>(endOfToday());

  const [startDateClassicValue, setStartDateClassicValue] =
    useState<Date | null>(new Date("2024-1-22 00:00:00"));
  const [endDateClassicValue, setEndDateClassicValue] = useState<Date | null>(
    endOfToday()
  );

  const { inputFields, setInputField } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const { data, loading, error } = useQuery<
    IStatisticsUsersData,
    IStatisticsUsersVars
  >(GET_STATISTICS_USERS, {
    variables: {
      endDate: inputFields.endDateUsers.inputProps.value || endDateUsersValue,
      startDate:
        inputFields.startDateUsers.inputProps.value || startDateUsersValue,
    },
    skip:
      (!inputFields.endDateUsers.inputProps.value && !endDateUsersValue) ||
      (!inputFields.startDateUsers.inputProps.value && !startDateUsersValue),
    onCompleted: () => {
      if (startDateUsersValue && endDateUsersValue) {
        enqueueSnackbar(
          `Preuzete su statistike za: ${format(
            startDateUsersValue,
            "dd.MM.yyyy."
          )} - ${format(endDateUsersValue, "dd.MM.yyyy.")}`,
          {
            variant: "success",
          }
        );
      }
    },
  });

  const {
    data: dataReviewsClassic,
    loading: loadingReviewsClassic,
    error: errorReviewsClassic,
  } = useQuery<IReviewsData, IReviewsVars>(ALL_REVIEWS, {
    variables: {
      endDate:
        inputFields.endDateClassic.inputProps.value || endDateClassicValue,
      startDate:
        inputFields.startDateClassic.inputProps.value || startDateClassicValue,
      interactiveTourId: undefined,
      classicTourId: inputFields.tourIdClassic.inputProps.value
        ? +inputFields.tourIdClassic.inputProps.value
        : undefined,
    },
    skip:
      (!inputFields.endDateClassic.inputProps.value && !endDateClassicValue) ||
      (!inputFields.startDateClassic.inputProps.value &&
        !startDateClassicValue) ||
      !inputFields.tourIdClassic.inputProps.value,
  });

  const {
    data: dataReviewsInteractive,
    loading: loadingReviewsInteractive,
    error: errorReviewsInteractive,
  } = useQuery<IReviewsData, IReviewsVars>(ALL_REVIEWS, {
    variables: {
      endDate:
        inputFields.endDateInteractive.inputProps.value ||
        endDateInteractiveValue,
      startDate:
        inputFields.startDateInteractive.inputProps.value ||
        startDateInteractiveValue,
      interactiveTourId: inputFields.tourIdInteractive.inputProps.value
        ? +inputFields.tourIdInteractive.inputProps.value
        : undefined,
      classicTourId: undefined,
    },
    skip:
      (!inputFields.endDateInteractive.inputProps.value &&
        !endDateInteractiveValue) ||
      (!inputFields.startDateInteractive.inputProps.value &&
        !startDateInteractiveValue) ||
      !inputFields.tourIdInteractive.inputProps.value,
  });

  const [
    queryAllInteractiveTours,
    {
      loading: loadingInteractiveTours,
      data: dataInteractiveTours,
      error: errorInteractiveTours,
    },
  ] = useLazyQuery<IInteractiveToursData>(ALL_INTERACTIVE_TOURS);

  const [
    queryAllClassicTours,
    {
      loading: loadingClassicTours,
      data: dataClassicTours,
      error: errorClassicTours,
    },
  ] = useLazyQuery<IClassicToursData>(ALL_CLASSIC_TOURS);

  const handleChangeUsersStart = (value: Date | null) => {
    setStartDateUsersValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("startDateUsers", formatedTime);
        } else {
          setInputField("startDateUsers", "");
        }
      } else setInputField("startDateUsers", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeUsersEnd = (value: Date | null) => {
    setEndDateUsersValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("endDateUsers", formatedTime);
        } else {
          setInputField("endDateUsers", "");
        }
      } else setInputField("endDateUsers", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const {
    data: dataInteractive,
    loading: loadingInteractive,
    error: errorInteractive,
  } = useQuery<IStatisticsInteractiveTourData, IStatisticsInteractiveTourVars>(
    GET_STATISTICS_INTERACTIVE_TOUR,
    {
      variables: {
        endDate:
          inputFields.endDateInteractive.inputProps.value ||
          endDateInteractiveValue,
        startDate:
          inputFields.startDateInteractive.inputProps.value ||
          startDateInteractiveValue,
        interactiveTourId: +inputFields.tourIdInteractive.inputProps.value,
      },
      skip:
        (!inputFields.endDateInteractive.inputProps.value &&
          !endDateInteractiveValue) ||
        (!inputFields.startDateInteractive.inputProps.value &&
          !startDateInteractiveValue) ||
        !inputFields.tourIdInteractive.inputProps.value,

      onCompleted: () => {
        if (startDateInteractiveValue && endDateInteractiveValue) {
          enqueueSnackbar(
            `Preuzete su statistike za: ${format(
              startDateInteractiveValue,
              "dd.MM.yyyy."
            )} - ${format(endDateInteractiveValue, "dd.MM.yyyy.")}`,
            {
              variant: "success",
            }
          );
        }
      },
    }
  );

  const {
    data: dataInteractiveCompleteness,
    loading: loadingInteractiveCompleteness,
    error: errorInteractiveCompleteness,
  } = useQuery<
    IStatisticsInteractiveTourCompletenessData,
    IStatisticsInteractiveTourCompletenessVars
  >(GET_STATISTICS_INTERACTIVE_TOUR_COMPLETENESS, {
    variables: {
      endDate:
        inputFields.endDateInteractive.inputProps.value ||
        endDateInteractiveValue,
      startDate:
        inputFields.startDateInteractive.inputProps.value ||
        startDateInteractiveValue,
      interactiveTourId: +inputFields.tourIdInteractive.inputProps.value,
    },
    skip:
      (!inputFields.endDateInteractive.inputProps.value &&
        !endDateInteractiveValue) ||
      (!inputFields.startDateInteractive.inputProps.value &&
        !startDateInteractiveValue) ||
      !inputFields.tourIdInteractive.inputProps.value,
  });

  // console.log(inputFields.tourIdClassic);

  const handleChangeInteractiveStart = (value: Date | null) => {
    setStartDateInteractiveValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("startDateInteractive", formatedTime);
        } else {
          setInputField("startDateInteractive", "");
        }
      } else setInputField("startDateInteractive", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeInteractiveEnd = (value: Date | null) => {
    setEndDateInteractiveValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("endDateInteractive", formatedTime);
        } else {
          setInputField("endDateInteractive", "");
        }
      } else setInputField("endDateInteractive", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const {
    data: dataClassic,
    loading: loadingClassic,
    error: errorClassic,
  } = useQuery<IStatisticsClassicTourData, IStatisticsClassicTourVars>(
    GET_STATISTICS_CLASSIC_TOUR,
    {
      variables: {
        endDate:
          inputFields.endDateClassic.inputProps.value ||
          endDateInteractiveValue,
        startDate:
          inputFields.startDateClassic.inputProps.value ||
          startDateInteractiveValue,
        classicTourId: +inputFields.tourIdClassic.inputProps.value,
      },
      skip:
        (!inputFields.endDateClassic.inputProps.value &&
          !endDateClassicValue) ||
        (!inputFields.startDateClassic.inputProps.value &&
          !startDateClassicValue) ||
        !+inputFields.tourIdClassic.inputProps.value,

      onCompleted: () => {
        if (startDateClassicValue && endDateClassicValue) {
          enqueueSnackbar(
            `Preuzete su statistike za: ${format(
              startDateClassicValue,
              "dd.MM.yyyy."
            )} - ${format(endDateClassicValue, "dd.MM.yyyy.")}`,
            {
              variant: "success",
            }
          );
        }
      },
    }
  );

  const handleChangeClassicStart = (value: Date | null) => {
    setStartDateClassicValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("startDateClassic", formatedTime);
        } else {
          setInputField("startDateClassic", "");
        }
      } else setInputField("startDateClassic", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeClassicEnd = (value: Date | null) => {
    setEndDateClassicValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("endDateClassic", formatedTime);
        } else {
          setInputField("endDateClassic", "");
        }
      } else setInputField("endDateClassic", "");
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === "2") {
      queryAllInteractiveTours();
      return;
    }
    if (activeTab === "3") {
      queryAllClassicTours();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getAverageReview = (data: IReviewsData | undefined | null) => {
    // console.log(data);
    if (!data?.allReviews?.length) {
      return 0;
    }
    let total = 0;
    data.allReviews.forEach((item) => {
      total += item.stars;
    });
    if (total) {
      return (total / data.allReviews.length).toFixed(2);
    }
    return 0;
  };

  const getAverageCompletionRate = (
    data: IStatisticsInteractiveTourCompletenessData | undefined | null
  ) => {
    // console.log(data);
    if (!data?.allStatisticsInteractiveTourCompleteness?.length) {
      return 0;
    }

    let totalCompleted = 0;
    let totalAttempts = 0;
    data.allStatisticsInteractiveTourCompleteness.forEach((item) => {
      totalCompleted += item.completed;
      totalAttempts += item.completed + item.skipped + item.ignored;
    });
    if (totalAttempts > 0) {
      return ((totalCompleted / totalAttempts) * 100).toFixed(2);
    }
    return 0;
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Korisnici" value="1" />
          <Tab label="Interaktivne ture" value="2" />
          <Tab label="Audio ture" value="3" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div
            className={cx(
              globalClasses.paperTitle,
              globalClasses.justifySpaceBetween
            )}
          >
            <Typography variant="h6">Statistika</Typography>
          </div>
          <div className={globalClasses.paperContainer}>
            <TabPanel value="1" className={globalClasses.tabPanel}>
              <div className={joinClassNames([classes.container])}>
                <div className={globalClasses.justifySpaceBetween}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGb}
                  >
                    <DatePicker
                      label={inputFields.startDateUsers.inputProps.label}
                      value={startDateUsersValue}
                      onChange={handleChangeUsersStart}
                      disableFuture
                      minDate={new Date("2024-1-22 00:00:00")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={inputFields.startDateUsers.inputProps.error}
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                    <DatePicker
                      label={inputFields.endDateUsers.inputProps.label}
                      value={endDateUsersValue}
                      disableFuture
                      minDate={new Date("2024-1-22 00:00:00")}
                      onChange={handleChangeUsersEnd}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={inputFields.endDateUsers.inputProps.error}
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <DataHandlerComponent
                    hasData={
                      data?.getStatisticsUsers !== undefined ||
                      data?.getStatisticsUsers !== null
                    }
                    loading={loading}
                    error={Boolean(error)}
                  >
                    <h1>
                      Ukupno registrovanih korisnika:{" "}
                      <span className={classes.price}>
                        {data?.getStatisticsUsers ?? "0"}
                      </span>
                    </h1>
                  </DataHandlerComponent>

                  <InfoShowcase title="Podaci su anonimni, ne prikazuju jedinstvene posete." />
                </div>
              </div>
            </TabPanel>

            <TabPanel value="2" className={globalClasses.tabPanel}>
              <div className={joinClassNames([classes.container])}>
                <div className={globalClasses.justifySpaceBetween}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGb}
                  >
                    <DatePicker
                      label={inputFields.startDateInteractive.inputProps.label}
                      value={startDateInteractiveValue}
                      onChange={handleChangeInteractiveStart}
                      disableFuture
                      minDate={new Date("2024-1-22 00:00:00")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            inputFields.startDateInteractive.inputProps.error
                          }
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                    <DatePicker
                      label={inputFields.endDateInteractive.inputProps.label}
                      value={endDateInteractiveValue}
                      disableFuture
                      minDate={new Date("2024-1-22 00:00:00")}
                      onChange={handleChangeInteractiveEnd}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            inputFields.endDateInteractive.inputProps.error
                          }
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <TextField
                  {...inputFields.tourIdInteractive.inputProps}
                  margin="normal"
                  select
                >
                  {dataInteractiveTours?.allInteractiveTours?.length ? (
                    dataInteractiveTours.allInteractiveTours.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.locale.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Učitavanje...</MenuItem>
                  )}
                </TextField>
                <div>
                  <DataHandlerComponent
                    hasData={
                      dataInteractive?.getStatisticsInteractiveTour !==
                        undefined ||
                      dataInteractive?.getStatisticsInteractiveTour !== null
                    }
                    loading={
                      loadingInteractive ||
                      loadingInteractiveTours ||
                      loadingReviewsInteractive ||
                      loadingInteractiveCompleteness
                    }
                    error={Boolean(
                      errorInteractive ||
                        errorInteractiveTours ||
                        errorReviewsInteractive ||
                        errorInteractiveCompleteness
                    )}
                  >
                    <h1>
                      Ukupno poseta:{" "}
                      <span className={classes.price}>
                        {dataInteractive?.getStatisticsInteractiveTour ?? "0"}
                      </span>
                    </h1>
                    <InfoShowcase title="Podaci su anonimni, ne prikazuju jedinstvene posete." />

                    <h1>
                      Prosečna ocena:{" "}
                      <span className={classes.price}>
                        {inputFields.tourIdInteractive.inputProps.value
                          ? getAverageReview(dataReviewsInteractive)
                          : 0}
                      </span>
                    </h1>

                    <TableContainer className={globalClasses.tableContainer}>
                      <Table
                        className={globalClasses.table}
                        // sx={{ minWidth: 650 }}
                      >
                        <TableHead>
                          <TableRow
                          // className={classes.tableRow}
                          >
                            <TableCell width={32} align="left">
                              #
                            </TableCell>
                            <TableCell width={32} align="left">
                              Zvezde
                            </TableCell>
                            <TableCell align="left">Recenzija</TableCell>
                            <TableCell width={160} align="left">
                              Kreirano
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataReviewsInteractive?.allReviews?.length &&
                          inputFields.tourIdInteractive.inputProps.value
                            ? dataReviewsInteractive?.allReviews?.map(
                                (item, i) => {
                                  return (
                                    <ReviewItem
                                      index={i + 1}
                                      key={item.id}
                                      item={item}
                                    />
                                  );
                                }
                              )
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <h1>
                      Stopa završetka:{" "}
                      <span className={classes.price}>
                        {inputFields.tourIdInteractive.inputProps.value
                          ? getAverageCompletionRate(
                              dataInteractiveCompleteness
                            )
                          : 0}
                        %
                      </span>
                    </h1>

                    <TableContainer className={globalClasses.tableContainer}>
                      <Table
                        className={globalClasses.table}
                        // sx={{ minWidth: 650 }}
                      >
                        <TableHead>
                          <TableRow
                          // className={classes.tableRow}
                          >
                            <TableCell width={32} align="left">
                              #
                            </TableCell>
                            <TableCell width={32} align="left">
                              Završeno
                            </TableCell>
                            <TableCell width={32} align="left">
                              Preskočeno
                            </TableCell>
                            <TableCell width={32} align="left">
                              Ignorisano
                            </TableCell>
                            <TableCell width={32} align="left">
                              Trajanje
                            </TableCell>
                            <TableCell width={120} align="left">
                              Kreirano
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataInteractiveCompleteness
                            ?.allStatisticsInteractiveTourCompleteness
                            ?.length &&
                          inputFields.tourIdInteractive.inputProps.value
                            ? dataInteractiveCompleteness?.allStatisticsInteractiveTourCompleteness?.map(
                                (item, i) => {
                                  return (
                                    <CompletenessItem
                                      index={i + 1}
                                      key={item.id}
                                      item={item}
                                    />
                                  );
                                }
                              )
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DataHandlerComponent>
                </div>
              </div>
            </TabPanel>

            <TabPanel value="3" className={globalClasses.tabPanel}>
              <div className={joinClassNames([classes.container])}>
                <div className={globalClasses.justifySpaceBetween}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGb}
                  >
                    <DatePicker
                      label={inputFields.startDateClassic.inputProps.label}
                      value={startDateClassicValue}
                      onChange={handleChangeClassicStart}
                      disableFuture
                      minDate={new Date("2024-1-22 00:00:00")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={inputFields.startDateClassic.inputProps.error}
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                    <DatePicker
                      label={inputFields.endDateClassic.inputProps.label}
                      value={endDateClassicValue}
                      disableFuture
                      minDate={new Date("2024-1-22 00:00:00")}
                      onChange={handleChangeClassicEnd}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={inputFields.endDateClassic.inputProps.error}
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <TextField
                  {...inputFields.tourIdClassic.inputProps}
                  margin="normal"
                  select
                >
                  {dataClassicTours?.allClassicTours?.length ? (
                    dataClassicTours.allClassicTours.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.locale?.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Učitavanje...</MenuItem>
                  )}
                </TextField>
                <div>
                  <DataHandlerComponent
                    hasData={
                      dataClassic?.getStatisticsClassicTour !== undefined ||
                      dataClassic?.getStatisticsClassicTour !== null
                    }
                    loading={
                      loadingClassic ||
                      loadingClassicTours ||
                      loadingReviewsClassic
                    }
                    error={Boolean(
                      errorClassic || errorClassicTours || errorReviewsClassic
                    )}
                  >
                    <h1>
                      Ukupno započeto:{" "}
                      <span className={classes.price}>
                        {dataClassic?.getStatisticsClassicTour.started ?? "0"}
                      </span>
                    </h1>
                    <h1>
                      Ukupno završeno:{" "}
                      <span className={classes.price}>
                        {dataClassic?.getStatisticsClassicTour.finished ?? "0"}
                      </span>
                    </h1>
                    <h1>
                      Stopa završetka:{" "}
                      <span className={classes.price}>
                        {dataClassic?.getStatisticsClassicTour.started &&
                        dataClassic?.getStatisticsClassicTour.finished
                          ? (
                              (dataClassic?.getStatisticsClassicTour.finished /
                                dataClassic?.getStatisticsClassicTour.started) *
                              100
                            ).toFixed(2)
                          : 0}
                        %
                      </span>
                    </h1>
                    <InfoShowcase title="Podaci su anonimni, ne prikazuju jedinstvene posete." />

                    <h1>
                      Prosečna ocena:{" "}
                      <span className={classes.price}>
                        {inputFields.tourIdClassic.inputProps.value
                          ? getAverageReview(dataReviewsClassic)
                          : 0}
                      </span>
                    </h1>

                    <TableContainer className={globalClasses.tableContainer}>
                      <Table
                        className={globalClasses.table}
                        // sx={{ minWidth: 650 }}
                      >
                        <TableHead>
                          <TableRow
                          // className={classes.tableRow}
                          >
                            <TableCell width={32} align="left">
                              #
                            </TableCell>
                            <TableCell width={32} align="left">
                              Zvezde
                            </TableCell>
                            <TableCell align="left">Recenzija</TableCell>
                            <TableCell width={160} align="left">
                              Kreirano
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataReviewsClassic?.allReviews?.length &&
                          inputFields.tourIdClassic.inputProps.value
                            ? dataReviewsClassic?.allReviews?.map((item, i) => {
                                return (
                                  <ReviewItem
                                    index={i + 1}
                                    key={item.id}
                                    item={item}
                                  />
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DataHandlerComponent>
                </div>
              </div>
            </TabPanel>
          </div>
        </Paper>
      </TabContext>
    </PageLayout>
  );
};
